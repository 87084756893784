import React, { useState } from "react";
import Sidebar from "../components/Sidebar";

const Crawling_Websites = () => {
  const [urls] = useState([
    {
      id: 1,
      url: "https://in.jobsora.com/",
      status: "Active",
      items: 150,
      lastCrawled: "2025-01-29",
    },
    {
      id: 2,
      url: "https://www.teachaway.com/",
      status: "Active",
      items: 75,
      lastCrawled: "2025-01-29",
    },
    {
      id: 3,
      url: "https://www.seekteachers.com/jobs.asp?country_id=12",
      status: "Active",
      items: 0,
      lastCrawled: "2025-01-29",
    },
  ]);

  return (
    <div className="flex bg-gray-100 min-h-screen">
      <Sidebar />
      <main className="w-full p-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold mb-6">Crawling URLs</h1>

          <div className="bg-white rounded-lg shadow">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-yellow-200">
                  <tr>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      URL
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      Status
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      Last Jobs Found
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      Last Crawled
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {urls.map((item) => (
                    <tr className="hover:bg-gray-200" key={item.id}>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {item.url}
                      </td>
                      <td className="px-6 py-4">
                        <span
                          className={`px-2 py-1 text-xs font-semibold rounded-full
                          ${
                            item.status === "Active"
                              ? "bg-green-100 text-green-800"
                              : "bg-red-100 text-red-800"
                          }`}
                        >
                          {item.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {item.items}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {item.lastCrawled}
                      </td>
                      <td className="px-6 py-4 text-sm">
                        {item.status === "Active" && (
                          <button className="text-red-600 hover:text-red-800 mr-3">
                            Pause Crwaling
                          </button>
                        )}

                        {item.status === "Paused" && (
                          <button className="text-green-600 hover:text-blue-800 mr-3">
                            Activate
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Crawling_Websites;
