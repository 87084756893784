import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { host } from "../config";

const Crawled_Jobs = () => {
  const [selectedWebsite, setSelectedWebsite] = useState('jobsora');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchJobs = async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams({
        website: selectedWebsite,
        startDate,
        endDate
      });

      const response = await fetch(`${host}/api/v1/admin/crawled_jobs?${params}`);
      if(response.status === 200){
        const data = await response.json();
        setJobs(data);
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchJobs();
  }, [selectedWebsite, startDate, endDate]);

 

  return (
    <div className="flex bg-gray-100">
      <Sidebar />
      <main className="w-full p-8">
        <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">Job Listings</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <select
            value={selectedWebsite}
            onChange={(e) => setSelectedWebsite(e.target.value)}
            className="p-2 border rounded-lg bg-white shadow-sm"
          >
            <option value="jobsora">Job Sora</option>
            <option value="teachaway">Teach Away</option>
            <option value="seekteachers">Seek Teachers</option>
          </select>

          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="p-2 border rounded-lg bg-white shadow-sm"
          />

          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="p-2 border rounded-lg bg-white shadow-sm"
          />
        </div>

        {loading ? (
          <div className="text-center py-8">Loading...</div>
        ) : (
          <div className="space-y-4">
            {jobs.map((job, index) => (
              <div 
                key={index}
                className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
              >
                <div className="flex justify-between items-start">
                  <div className="space-y-2">
                    <h2 className="text-xl font-semibold text-gray-800">{job.title}</h2>
                    <p className="text-gray-600">{job.employer}</p>
                    <p className="text-sm text-gray-500">
                      {job.city} • {job.onl_ofl}
                    </p>
                    {job.salary_range && (
                      <p className="text-sm text-gray-600">
                        Salary: ${job.salary_range.min.toLocaleString()} - ${job.salary_range.max.toLocaleString()}
                      </p>
                    )}
                  </div>
                  <span className="text-sm text-gray-500">
                    {new Date(job.createdAt).toLocaleDateString()}
                  </span>
                </div>
                <p className="mt-4 text-gray-700">{job.description}</p>
              </div>
            ))}
          </div>
        )}
        </div>
      </main>
    </div>
  );
};

export default Crawled_Jobs;
